import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { FaWindowClose } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { apiPost } from "../../Util/ApiRequest";
import { ENDPOINTS } from "../../Util/EndPoint";
import CustomTooltip from "./CustomTooltip";
import Loader from "./Loader";
import MediaGallery from "./MediaGallery";
import WishTagsComponent from "./WishTagsComponent";

const InterActiveWishes = () => {
  const [currentUrl, setCurrentUrl] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [items, setItem] = useState([]);

  const [mediaModal, setMediaModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const [userId, setUserId] = useState(0);

  useEffect(() => {
    let userId = /[^/]*$/.exec(window.location.href)[0];
    setUserId(userId);
    const url = "";
    setCurrentUrl(url);
    getAllUsersWithPagination(url, userId);
  }, []);

  const handlePageClick = (item) => {
    const url = `?page=${item.selected + 1}&limit=10`;
    getAllUsersWithPagination(url, userId);
  };

  const selectMediaSlider = (item) => {
    console.log(item, "item");
    setMediaModal(true);
    setSelectedItem(item);
  };
  const getAllUsersWithPagination = (url, userId) => {
    let body = {
      user_id: userId,
    };
    setLoader(true);
    apiPost(
      ENDPOINTS.SingleUserInterActiveWises + url,
      body,
      (res) => {
        setLoader(false);
        setItem(res?.data?.results);
        setTotalPage(res?.data?.meta?.totalPages);
      },
      (error) => {
        setLoader(false);
      }
    );
  };

  return (
    <>
      <div>
        <div className="table-main-header p-2">
          <h5 className="text-white my-auto p-2">My InterActive Wishes</h5>
        </div>
        <div className="table-responsive">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>

                <th>Wishes Text</th>
                <th>Wish Media</th>
                <th>Saved Wish</th>
                <th>Tags</th>
              </tr>
            </thead>
            <tbody>
              {items?.length > 0 &&
                items.map((item, index) => (
                  <tr key={index}>

                    <td className="position-relative">
                      <p className="mb-0 ">{item?.user?.user_name}</p>
                    </td>
                    <td className="position-relative">
                      <p className="mb-0">{item?.user?.email}</p>
                    </td>
                    <td className="position-relative">
                      <p className="mb-0">
                        {item?.user?.profile_meta?.mobile_number}
                      </p>
                    </td>
                    <td>
                      <CustomTooltip descriptionPro={item?.wish_text} />
                    </td>
                    <td>
                      <button
                        disabled={item?.media?.length > 0 ? false : true}
                        className="btn btn-primary"
                        onClick={() => selectMediaSlider(item)}
                      >
                        {item?.media?.length > 0
                          ? " show media"
                          : "media not found"}
                      </button>
                    </td>
                    <td className="position-relative">
                      <p className="mb-0">
                        {item?.save_wish ? "Yes" : "No"}
                      </p>
                    </td>
                    <td className="position-relative">
                      <WishTagsComponent tagsArray={item?.wish_tags} />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        <ReactPaginate
          className="pagination-custom"
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={10}
          pageCount={totalPage}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
      </div>
      <div className="">{loader && <Loader />}</div>
      <Modal show={mediaModal}>
        <button className="close-btn" onClick={() => setMediaModal(false)}>
          {<FaWindowClose className="close-icon" />}
        </button>
        <MediaGallery selectedItemPro={selectedItem} />
      </Modal>
    </>
  );
};

export default InterActiveWishes;
