import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import AdminLayout from "./components/layout/AdminLayout";
import "./custom.scss";
import Dashboard from "./pages/Dashboard";
import ErrorPage from "./pages/ErrorPage";
import Login from "./pages/Login";
import ReportedWishes from "./pages/ReportedWishes";
import Users from "./pages/Users";
import UsersDetail from "./pages/UsersDetail";
import WishTags from "./pages/WishTags";

function App () {
  return (
    <>
      <div className="App">
        <Router>
          <Routes>
            <Route exact path={``} element={<Login />} />
            <Route path={`/admin`} element={<AdminLayout />}>
              <Route exact path="dashboard" element={<Dashboard />} />
              <Route exact path="wish-tags" element={<WishTags />} />
              <Route exact path="user-detail/:id" element={<UsersDetail />} />
              <Route exact path="users" element={<Users />} />
              <Route exact path="reported-wishes" element={<ReportedWishes />} />
            </Route>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
