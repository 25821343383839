import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import HeaderCard from "../global/HeaderCard";
import SidebarComponent from "../sidebar/Sidebar";

const AdminLayout = (props) => {

  return (
    <>
      <div className="main-layout">
        <SidebarComponent />
        <main className="main-content p-4">
          <HeaderCard />
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default AdminLayout;
