import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { FaWindowClose } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { apiGetAuth } from "../Util/ApiRequest";
import { ENDPOINTS } from "../Util/EndPoint";
import CustomTooltip from "../components/global/CustomTooltip";
import Loader from "../components/global/Loader";
import MediaGallery from "../components/global/MediaGallery";
import { UserAvatar } from "../components/global/UserAvatar";



const ReportedWishes = () => {

  const [showSelected, setShowSelected] = useState(false);

  const [currentUrl, setCurrentUrl] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [items, setItem] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    const url = "";
    setCurrentUrl(url);
    getAllReportedWishes(url);
  }, []);

  const handlePageClick = (item) => {
    const url = `?page=${item.selected + 1}&limit=10`;
    getAllReportedWishes(url);
  };

  const selectedPost = (item) => {
    console.log(item, "item");
    setSelectedItem(item);
    setShowSelected(true)
  };

  const getAllReportedWishes = (url) => {
    setLoader(true);
    apiGetAuth(
      ENDPOINTS.AllReportedWishes + url,
      (res) => {
        setLoader(false);
        setItem(res?.results);
        setTotalPage(res?.meta?.totalPages);
      },
      (error) => {
        setLoader(false);
      }
    );
  };

  return (
    <>
      <div>
        <div className="table-main-header p-2">
          <h5 className="text-white my-auto p-2">Reported wishes</h5>
        </div>
        <div className="table-responsive">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Wish id</th>
                <th>Reported by</th>
                <th>Reported user</th>
                <th>Reason</th>
                <th>Report details</th>
                {/* <th>Delete Post </th> */}
              </tr>
            </thead>
            <tbody>
              {items?.length > 0 &&
                items.map((item, index) => (
                  <tr key={index}>
                    <td className="position-relative">
                      <p className="mb-0">{item?.wish_id}</p>
                    </td>
                    <td className="position-relative">
                      <p className="mb-0">{item?.reported_by?.email}</p>
                    </td>
                    <td className="position-relative">
                      <p className="mb-0 services-para">
                        {item?.report_wishes?.user?.email}
                      </p>
                    </td>
                    <td className="position-relative">
                      <CustomTooltip descriptionPro={item?.description} />
                    </td>
                    <td>
                      <button
                        onClick={() => selectedPost(item)}
                        className="btn btn-primary"
                      >
                        Post Details
                      </button>
                    </td>
                    {/* <td>
                      <button className="btn btn-danger">
                        {<FaTrashAlt />}
                      </button>
                    </td> */}
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        <ReactPaginate
          className="pagination-custom"
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={10}
          pageCount={totalPage}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
      </div>


      <div className="">{loader && <Loader />}</div>

      <Modal show={showSelected}>
        <button className="close-btn" onClick={() => setShowSelected(false)}>
          {<FaWindowClose className="close-icon" />}
        </button>
        <div className="row p-3">
          <div className="col-md-12">
            <div className="table-main-header p-2">
              <h5 className="text-white my-auto p-2">Reported wish</h5>
            </div>
            {selectedItem && (

              <Table striped bordered hover className='user-detail-info-table'>

                <tbody>

                  <tr>
                    <th>Reporter</th>
                    <td><UserAvatar user={selectedItem?.reported_by} /> </td>
                  </tr>
                  <tr>
                    <th>Issue Id</th>
                    <td>{selectedItem?.id}</td>
                  </tr>
                  <tr>
                    <th>Issue Date</th>
                    <td>{moment(selectedItem?.created_at).format("LL")} </td>
                  </tr>

                  <tr>
                    <th>Issue</th>
                    <td>{selectedItem?.description}</td>
                  </tr>
                  <tr>
                    <th>Wish text</th>
                    <td>{selectedItem?.report_wishes?.wish_text}</td>
                  </tr>

                </tbody>
              </Table>

            )}

            <div className="mb-2">

              {selectedItem?.report_wishes?.media?.length > 0 && (
                <div>
                  <h5 className="text-white my-auto p-2 bg-primary mb-2">Post Media </h5>
                  <MediaGallery selectedItemPro={selectedItem?.report_wishes} />
                </div>
              )}

            </div>

          </div>

        </div>

      </Modal>
    </>
  );
};

export default ReportedWishes;
