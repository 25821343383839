import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { apiGetAuth, apiPost } from "../Util/ApiRequest";
import { ENDPOINTS } from "../Util/EndPoint";
import UsersYearBar from "../components/charts/UsersYearBar";
import WishesYearLineChart from "../components/charts/WishesYearLineChart";



const Dashboard = () => {
  useEffect(() => {
    getHeaderData();
  }, []);
  const [headerData, setHeaderData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [metaValue, setMetaValue] = useState("");

  const getHeaderData = () => {
    apiGetAuth(
      ENDPOINTS.AppInfoGet,
      (res) => {
        setHeaderData(res)

      },
      (error) => {
        console.log(error)
      }
    );
  };


  const handleItem = (item) => {
    setSelectedItem(item)
    setShowModal(true)

  }

  const handleSubmit = (event) => {
    event.preventDefault();
    apiPost(
      ENDPOINTS.AppInfoUpdate,
      {
        meta_value: metaValue,
        meta_key: selectedItem?.meta_key
      },
      (res) => {
        setShowModal(false)
        getHeaderData();

      },
      (error) => {
        console.log(error)
        setShowModal(false)
        getHeaderData();
      }
    );
  };


  return (
    <>
      <Container fluid className="mt-4">
        <Row>
          <Col md={6} >
            <Card>
              <UsersYearBar />
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <WishesYearLineChart />
            </Card>
          </Col>


        </Row>
        <Row className='mt-4'>
          {headerData?.length > 0 &&
            headerData.map((item, index) => (
              <Col md={6} key={index}>
                <div className="mt-2 card-header-customs ">

                  <Card >
                    <Card.Body>
                      <Card.Title>{item?.meta_key?.replace(/_/g, " ")}</Card.Title>
                      <CKEditor
                        disabled={true}
                        editor={ClassicEditor}
                        data={item?.meta_value}
                        onReady={editor => {
                          console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setMetaValue(data)
                          console.log({ event, editor, data });
                        }}
                        onBlur={(event, editor) => {
                          console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log('Focus.', editor);
                        }}
                      />
                      <Button variant="primary" className='mt-2' onClick={() => handleItem(item)}>Detail</Button>
                    </Card.Body>
                  </Card>
                </div >
              </Col>
            ))}
        </Row>
      </Container>

      <Modal size='lg' show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header
          closeButton
          className="bg-primary text-white custom-modal-header"
        >
          <Modal.Title className="font-18">{selectedItem?.meta_key?.replace(/_/g, " ")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit}
          >
            <input
              className="form-control mb-4"
              type="hidden"
              required
              defaultValue={selectedItem?.meta_key}
              name="meta_key"
            />

            <CKEditor
              editor={ClassicEditor}
              data={selectedItem.meta_value}
              onReady={editor => {
                // You can store the "editor" and use when it is needed.
                console.log('Editor is ready to use!', editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setMetaValue(data)
                console.log({ event, editor, data });
              }}
              onBlur={(event, editor) => {
                console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                console.log('Focus.', editor);
              }}
            />
            <button className="btn btn-secondary mt-3" type="submit">
              submit
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Dashboard;
