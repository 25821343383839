import React from 'react';
import { BASE_IMG_URL } from '../../Util/EndPoint';
import CatImg from "../../assets/images/user.jpg";


export const UserAvatar = ({ user }) => {
    return (
        <div class="d-flex align-items-center ">
            <div class="symbol symbol-45px me-2 border border-primary">
                <img src={user?.profile_meta?.profile_image ? BASE_IMG_URL + user?.profile_meta?.profile_image : CatImg} alt="dsdasda" /></div>
            <div class="d-flex justify-content-start flex-column">
                <p class="text-dark fw-bold text-hover-primary fs-6 mb-0">{user?.user_name}</p>
                <span class="text-muted fw-semibold text-muted d-block fs-7">{user?.profile_meta?.name}</span>
            </div>
        </div>

    )
}
