import React from 'react'

const WishTagsComponent = ({ tagsArray }) => {
  return (
    <>
      {tagsArray?.length > 0 &&
        tagsArray.map((item, index) => (
          <div key={index} className='wish-tags-all bg-primary text-white'>{item.tag?.name}</div>
        ))}
      {tagsArray?.length < 1 && (<div>-</div>)}
    </>
  )
}

export default WishTagsComponent