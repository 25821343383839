import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CompanyImg from "../../assets/images/companies.png";
import MarketPlaceImg from "../../assets/images/marketplace.png";
import IssuesImg from "../../assets/images/report.png";
import UserImg from "../../assets/images/users.png";
import { apiGetAuth } from "./../../Util/ApiRequest";
import { ENDPOINTS } from "./../../Util/EndPoint";
import Loader from "./Loader";



const HeaderCard = () => {
  useEffect(() => {
    getHeaderData();
  }, []);
  const [loader, setLoader] = useState(false);
  const [headerData, setHeaderData] = useState({});

  const getHeaderData = (url) => {
    setLoader(true);
    apiGetAuth(
      ENDPOINTS.AppHeader,
      (res) => {
        setHeaderData(res)
        setLoader(false);

      },
      (error) => {
        console.log(error)
        setLoader(false);

      }
    );
  };
  return (
    <>
      <Container fluid className="mt-2 card-header-customs">
        <Row>
          <Col xl="3" lg="6" md="12">
            <div className="header-card">
              <div className="card-inner ">
                <div>
                  <img className="user-img" src={UserImg} alt="Users Img" />
                </div>
                <div className="ms-4">
                  <h5 className="mb-0 text-white">{headerData.user_count}</h5>
                  <p className="mb-0 font-12 text-white">Total User</p>
                </div>
              </div>
            </div>
          </Col>
          <Col xl="3" lg="6" md="12">
            <div className="header-card">
              <div className="card-inner ">
                <div>
                  <img className="user-img " src={CompanyImg} alt="Users Img" />
                </div>
                <div className="ms-4">
                  <h5 className="mb-0 text-white">{headerData.wishes_count}</h5>
                  <p className="mb-0 font-12 text-white">Total wishes</p>
                </div>
              </div>
            </div>
          </Col>
          <Col xl="3" lg="6" md="12">
            <div className="header-card">
              <div className="card-inner ">
                <div>
                  <img
                    className="user-img"
                    src={MarketPlaceImg}
                    alt="Users Img"
                  />
                </div>
                <div className="ms-4">
                  <h5 className="mb-0 text-white">{headerData.report_wishes_count}</h5>
                  <p className="mb-0 font-12 text-white">
                    Total reported wishes
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xl="3" lg="6" md="12">
            <div className="header-card">
              <div className="card-inner ">
                <div>
                  <img className="user-img " src={IssuesImg} alt="Users Img" />
                </div>
                <div className="ms-4">
                  <h5 className="mb-0 text-white">{headerData.inter_active_wishes_count}</h5>
                  <p className="mb-0 font-12 text-white">
                    Total active wishes
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="">{loader && <Loader />}</div>
    </>
  );
};

export default HeaderCard;
