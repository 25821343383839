import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FaArrowUp, FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { GoVerified } from "react-icons/go";
import CatImg from "../../assets/images/user.jpg";
import "../../assets/pages-css/user-detail.css";
import InterActiveWishes from './InterActiveWishes';
import MyWishes from './MyWishes';
import UserOverView from './UserOverView';
import { BASE_IMG_URL } from '../../Util/EndPoint';
const UserProfileCard = ({ userObjPro }) => {
  return (

    <div className=' user-profile-main'>
      <div className='user-profile'>
        <div className='user-image-box'>
          <img src={userObjPro?.profile_meta?.profile_image ? BASE_IMG_URL + userObjPro?.profile_meta?.profile_image : CatImg} alt="profile image" className='user-image' />
        </div>

        <div className='user-info-box'>
          <div className='user-info-box-personal'>
            <h3>{userObjPro?.user_name} <span className='font-icon-14 text-success'><GoVerified /></span></h3>
            <div className='info-icon'>
              <p><span className='font-icon-12'><FaEnvelope /></span>  {userObjPro?.email}</p>
              <p> <span className='font-icon-12'><FaPhoneAlt /></span> {userObjPro?.profile_meta?.mobile_number}</p>
            </div>
          </div>
          <div className='user-info-app'>
            <div className='info-icon-app'>
              <h5><span className='text-success font-icon-12'><FaArrowUp /></span> {userObjPro?.my_wish_count ? userObjPro.my_wish_count : 0}</h5>
              <p>my wishes</p>
            </div>
            <div className='info-icon-app'>
              <h5><span className='text-success font-icon-12'><FaArrowUp /></span> {userObjPro?.inter_active_wishes_count ? userObjPro.inter_active_wishes_count : 0}</h5>
              <p>interactive wishes</p>
            </div>
          </div>
        </div>




      </div>

      <div className='mt-4  user-profile-nav-tabs'>
        <Tabs
          defaultActiveKey="overview"
          className="mb-3"
        >
          <Tab eventKey="overview" title="Overview">
            <UserOverView userObjPro={userObjPro} />
          </Tab>
          <Tab eventKey="my-wishes" title="My Wishes">
            <MyWishes />
          </Tab>
          <Tab eventKey="interactive-wishes" title="Interactive Wishes">
            <InterActiveWishes />
          </Tab>
        </Tabs>
      </div>
    </div>

  )
}

export default UserProfileCard