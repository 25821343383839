

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const BASE_IMG_URL = process.env.REACT_APP_API_FILES_URL;
export const ENDPOINTS = {
  //User
  UserLogin: `${BASE_URL}api/auth/login`,

  //All Users
  AllUsersWithPagination: `${BASE_URL}api/admin/user/get-all-user`,
  UserActiveDeActiveByAdmin: `${BASE_URL}api/admin/user/active-deactive-user`,

  //Tags
  PaginatedWishTags: `${BASE_URL}api/admin/tag-wish/get-all-tags`,
  CreateTagByAdmin: `${BASE_URL}api/admin/tag-wish/create`,
  UpdateTagByAdmin: `${BASE_URL}api/admin/tag-wish/update-tag`,

  //Wishes
  AllUserWishes: `${BASE_URL}api/admin/user/single-user-wishes`,

  //inter active wishes
  SingleUserInterActiveWises: `${BASE_URL}api/admin/user/single-user-wishes-inter-active-wishes`,


  AllReportedWishes: `${BASE_URL}api/admin/wishes/reported-wishes`,

  // user details
  UserDetails: `${BASE_URL}api/admin/user/user-detail`,


  // header
  AppHeader: `${BASE_URL}api/admin/user/dashboard-header`,

  //app info
  AppInfoGet: `${BASE_URL}api/admin/user/app-info`,
  AppInfoUpdate: `${BASE_URL}api/admin/user/app-info-update`,

  ChartCurrentYearUsers: `${BASE_URL}api/admin/chart/current-year-users`,
  ChartCurrentYearWishes: `${BASE_URL}api/admin/chart/current-year-wishes`,
};
